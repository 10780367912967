<div class="wrapper">
    <sf-chat-bubble-template
        [content]="getMessageContent()?.mimetype ?? 'UNKNOWN MEDIA'"
        [letter]="message?.chatLetter"
        [hoverMessage]="message?.name"
        [dateTime]="message?.insertedAt"
        [ticks]="message?.ticks"
        [direction]="message?.direction"
        [allowContentClick]="true"
        contentClickMessage="open image"
        (contentClicked)="toggleMedia()"
    ></sf-chat-bubble-template>

    <div *ngIf="mediaState$ | async as mediaState" class="media-section">
        <sf-loader-template
            [loading]="mediaState.loading"
            [hasData]="mediaState.data?.base64string"
            [error]="mediaState.error"
            [noDataRed]="true"
            [useDefaultError]="true"
        >
            <div loading class="loading-wrapper">
                <small-text-spinner size="2rem"> loading.. </small-text-spinner>
            </div>

            <ng-container
                *ngIf="mediaState.data?.visible"
                dataContent
                [ngSwitch]="message?.type"
            >
                <div *ngSwitchCase="'IMAGE'" class="video-or-image-container">
                    <img
                        [src]="mediaState.data?.base64string | safe : 'url'"
                        alt="whatsapp-image"
                        oncontextmenu="return false;"
                    />
                </div>

                <div *ngSwitchCase="'VIDEO'" class="video-or-image-container">
                    <video
                        [src]="mediaState.data?.base64string | safe : 'url'"
                        alt="whatsapp-video"
                        controls
                        controlsList="nodownload"
                        oncontextmenu="return false;"
                    ></video>
                </div>

                <div class="audio-container">
                    <audio
                        *ngSwitchCase="'AUDIO'"
                        [src]="mediaState.data?.base64string | safe : 'url'"
                        alt="whatsapp-audio"
                        controls
                        controlsList="nodownload"
                        oncontextmenu="return false;"
                    ></audio>
                </div>

                <span *ngSwitchDefault class="error"
                    >Unsupported Media Type</span
                >
            </ng-container>
        </sf-loader-template>
    </div>
</div>
