import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Utils } from "src/app/Utils";
import { environment } from "src/environments/environment";
import { WHATSAPP_SEARCH_PAYLOAD_DEFAULTS } from "../store/constants/whatsapp.constants";
import { WhatsappMessage } from "../store/types/whatsapp-message.interface";
import {
    WhatsappRequiredSearchPayload,
    WhatsappSearchPayload,
} from "../store/types/whatsapp-search-payload.interface";
import { SendWhatsappMessagePayload } from "../store/types/whatsapp-send-message-payload.interface";

@Injectable({
    providedIn: "root",
})
export class WhatsappService {
    constructor(private http: HttpClient) {}

    fetchMessages(payload: WhatsappRequiredSearchPayload) {
        const url = `${environment.whatsapp}/message/customer/search`;
        const finalPayload = Utils.Mappers.patch<
            Partial<WhatsappSearchPayload>
        >(WHATSAPP_SEARCH_PAYLOAD_DEFAULTS, payload);

        const headers = {
            "Content-Type": "application/json",
        };

        return this.http.post<{ data: WhatsappMessage[] }>(url, finalPayload, {
            headers,
        });
    }

    sendMessage(payload: SendWhatsappMessagePayload) {
        const url = `${environment.whatsapp}/message/whatsapp/text`;

        const headers = {
            "Content-Type": "application/json",
        };

        return this.http.post<{ data: any[] }>(url, payload, { headers });
    }
}
