import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofActionDispatched, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable, Subject, of } from 'rxjs';
import { delay, switchMap, take, takeUntil } from 'rxjs/operators';
import { ToggleModalByName } from '../core/store/actions/modal.actions';
import { CurrentLoadsheddingReportState } from '../shared/components/loadshedding/store/state/current-loadshedding-report.state';
import { CustomerTicket, ShortTicket } from '../shared/customer-ticket/interfaces/customer-ticket.interface';
import { AddTicketEvent } from '../shared/customer-ticket/store/actions/ticket-event.actions';
import { TicketEventHandlerService } from '../shared/customer-ticket/ticket-event-handler/ticket-event-handler.service';
import { SipPhoneService } from '../sip-phone/services/sip-phone.service';
import { VoiceCampaignActions } from '../sip-phone/store/actions/voice-campaign-actions';
import { VoiceCampaignState } from '../sip-phone/store/state/voice-campaign.state';
import { VoiceTicketState } from '../sip-phone/store/state/voice-ticket-info.state';
import { InteractionActions } from './store/actions/interaction-actions';
import { InteractionTicketActions } from './store/actions/interaction-ticket-actions';
import { InteractionType } from './store/interfaces/interaction-type.type';
import { InteractionTicketsState } from './store/state/interaction-tickets.state';
import { InteractionsState } from './store/state/interactions.state';
import { WhatsappMessageActions } from './whatsapp-interactions/store/actions/whatsapp-message-actions';
import { WhatsappNotificationState } from './whatsapp-interactions/store/state/whatsapp-notification.state';
import { CoreState } from '../core/store/state/core.state';
import { ActivatedRoute } from '@angular/router';
import { SFQueryParams } from '../shared/interfaces/sf-query-params.interface';
import { RefreshTicketActions } from './store/actions/refresh-ticket-actions';
import { SipPhoneState } from '../sip-phone/store/state/sip.state';
import { AgentGroupType } from '../shared/interfaces/agent-group-type.type';
import { Utils } from '../Utils';
import { RainAgent } from '../shared/components/agent/rain-agent.service';
import { ChatFunctions } from './chat-interaction/assets/chat-functions';
import { TICKET_STATES } from '../shared/customer-ticket/ticket-event-handler/assets/ticket.constants';
import { ContinueFromPostTicketModal } from './store/actions/interaction-actions/interactions.actions';
import { InteractionsModalState } from './store/state/interaction-modal-state';


//TODO: use view model for the interactions

@Component({
  selector: 'app-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss'],
})
export class InteractionsComponent implements OnInit, OnDestroy {
  @Select(CurrentLoadsheddingReportState.isOpen) isLoadsheddingBannerOpen$: Observable<boolean>;
  @Select(InteractionTicketsState.hasSelectedTicket) hasSelectedTicket$: Observable<boolean>;
  @Select(InteractionTicketsState.isLoading) loading$: Observable<boolean>;
  @Select(InteractionTicketsState.isNextPageLoading) isNextPageLoading$: Observable<boolean>;
  @Select(InteractionTicketsState.getTickets) shortTickets$: Observable<ShortTicket[]>;
  @Select(InteractionTicketsState.getError) error$: Observable<string>;
  @Select(InteractionTicketsState.getSelectedShortTicket) selectedShortTicket$: Observable<ShortTicket>;
  @Select(InteractionTicketsState.canLoadMore) canLoadMore$: Observable<boolean>;
  @Select(InteractionsState.getInteractionType) interactionType$: Observable<InteractionType>;
  @Select(WhatsappNotificationState.count) whatsappNotificationCount$: Observable<number>;
  @Select(CoreState.getOpenTicketsForGroup) openTicketsForGroup$: Observable<ShortTicket[]>;

  @Input() customerEmail: string;
  @Input() unknownCustomer = false;
  @Input() fromSearchPage = false;
  @Input() open = true;


  agentType: AgentGroupType = 'NORMAL';

  private readonly ngDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private ticketEventService: TicketEventHandlerService,
    private sipPhoneService: SipPhoneService,
    private actions$: Actions,
    private route: ActivatedRoute,
    private rainAgent: RainAgent,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new InteractionActions.SetIsActive(true));
    this.setAgentRoles();
    this.setupCampaignCallListener();

    //TODO: use router params for all ticket stuff
    this.route.queryParams
      .pipe(
        switchMap((params: SFQueryParams) => {

          const { ticketHexId } = params ?? {};

          if (ticketHexId) {
            return this.store.dispatch([
              new InteractionTicketActions.SetAssignedHexId(ticketHexId),
              new RefreshTicketActions.RefreshTicket(ticketHexId)
            ]);
          }
          else {
            return of(null);
          }

        }),
        take(1),
        takeUntil(this.ngDestroy$)
      )
      .subscribe(() => {

        if (this.unknownCustomer) {
          this.initUnknownCustomerTicket();
        }
        else {
          this.initCustomerTickets();
        }

      });

    this.store.dispatch(new WhatsappMessageActions.Init());

    this.actions$
    .pipe(
      ofActionDispatched(InteractionTicketActions.RefreshAllTickets),
      takeUntil(this.ngDestroy$)
    )
    .subscribe(() => this.unknownCustomer ? this.initUnknownCustomerTicket() : this.initCustomerTickets())
  }

  private setupCampaignCallListener() {
    const campaignCallEnded$ = this.actions$
      .pipe<InteractionActions.InteractionTicketInit>(ofActionSuccessful(VoiceCampaignActions.CampaignCallEnded));

    campaignCallEnded$
      .pipe(
        take(1),
        takeUntil(this.ngDestroy$)
      )
      .subscribe({
        next: () =>{
          this.agentType === 'CEC.COLLECTIONS.A' ?
            this.store.dispatch(new ToggleModalByName("post_campaign_call_modal", true))
            : this.handleButtonEvent(TICKET_STATES.CLOSED)

     }   })
  }

  private async createAutoTicket() {
    if (this.store.selectSnapshot(InteractionsState.createdAutoTicket)) {
      return null;
    }

    const callTicketSubject = this.store.selectSnapshot(VoiceTicketState.getCallTicketSubject);
    const { phoneNumber, queue } = this.store.selectSnapshot(SipPhoneState.getIncomingCallDetails) ?? {}
    const ticketName = `${callTicketSubject} #${phoneNumber}`
    const ticketGroup = queue === 'Support' ? 'CEC.IVR.A' : null

    let ticket: CustomerTicket = null;
    if (!this.customerEmail) {
      ticket = await this.ticketEventService.createUnknownCustomerTicket(ticketName, ticketGroup);
    }
    else {
      //Use the same field for name and description
      const ticketData = await this.ticketEventService.createNewTicket(ticketName, ticketName, this.customerEmail, false, ticketGroup);
      ticket = ticketData?.data?.data;
    }
    this.store.dispatch(new InteractionActions.CreatedAutoTicket(true));
    return ticket;
  }

  async initUnknownCustomerTicket() {
    const ticket = await this.checkCallStatus();

    const { hex_id } = ticket ?? {};
    if (!hex_id) {
      return;
    }

    //Add delay because event does not instantly show up
    setTimeout(() => this.showSelectedTicket(hex_id), 1000);
  }

  private async addCallTicketCreatedEvent(hexId: string) {
    const callTicketEventName = this.store.selectSnapshot(VoiceTicketState.getCallTicketEventName);
    const { phoneNumber } = this.store.selectSnapshot(SipPhoneState.getIncomingCallDetails) ?? {}
    const eventName = `${callTicketEventName} from ${phoneNumber}`
    return this.ticketEventService.addNewEvent(hexId, eventName);
  }

  initCustomerTickets() {
    this.store.dispatch(new InteractionTicketActions.Fetch({ email: this.customerEmail }))
      .pipe(
        take(1),
        delay(1500),
        takeUntil(this.ngDestroy$)
      ).subscribe(() => {

        const assignedHexId = this.store.selectSnapshot(InteractionTicketsState.getAssignedHexId);
        if (!assignedHexId) {
          this.checkCallStatus();
        }
        else {
          const ticket = this.store.selectSnapshot(InteractionTicketsState.getTicketByHexId(assignedHexId));
          this.onTicketInit(ticket);

          if (this.store.selectSnapshot(VoiceCampaignState.inCampaignCall)) {
            this.logCampaignEvent(ticket?.hex_id)
          }
        }
      });
  }

  private logCampaignEvent(hexId: string) {
    if (!hexId) {
      return;
    }

    const eventComment = this.store.selectSnapshot(VoiceTicketState.getCallTicketEventName);
    this.store.dispatch(new AddTicketEvent({ hexId, eventComment }));
  }


  /**
   * Check if an inbound ticket should be created for a known customer if the current sip session is active.
   */
  private async checkCallStatus() {
    const hasActiveSession = this.sipPhoneService.activeSession;
    const isIncomingCall = this.sipPhoneService.callType === "incoming";
    if (!hasActiveSession || !isIncomingCall) {
      return;
    }

    const ticket = await this.createAutoTicket();
    if (ticket?.hex_id) {
      await this.addCallTicketCreatedEvent(ticket.hex_id);
      this.onTicketInit(ticket);
      return ticket;
    }
  }

  private onTicketInit(ticket: CustomerTicket | ShortTicket) {
    this.store.dispatch(new InteractionActions.InteractionTicketInit(ticket));
  }

  onToggleOpen() {
    this.open = !this.open;
  }

  showSelectedTicket(hexId: string) {
    this.store.dispatch(new InteractionTicketActions.SetSelectedHexId(hexId));
  }

  openNewTicketModal() {
    this.store.dispatch(new ToggleModalByName("new_customer_ticket_modal", true));
  }

  loadNextPage() {
    const canLoadMore = this.store.selectSnapshot(InteractionTicketsState.canLoadMore);
    if (canLoadMore) {
      this.store.dispatch(new InteractionTicketActions.FetchNextPage(this.customerEmail));
    }
  }

  setInteractionType(type: InteractionType) {
    this.store.dispatch(new InteractionActions.SetInteractionType(type));
  }

  private async setAgentRoles() {
    const agentTeam = (await this.rainAgent.getAgentDetails()).getTeamRole();
    this.agentType = Utils.SfHelpers.getAgentGroupType(agentTeam);
}


handleButtonEvent(state_id: number) {
  if (this.agentType === 'CEC.CALLBACK.A' || this.agentType === 'CEC.TEAM.A'||((this.agentType === 'SALES' || this.agentType === 'RETENTIONS') && state_id !== TICKET_STATES.CLOSED)) {
    const isPrivateReply = this.store.selectSnapshot(InteractionsModalState.isPrivateReply);
    this.store.dispatch(new ContinueFromPostTicketModal(isPrivateReply));
    return;
  }
  else {
  this.store.dispatch(
      ChatFunctions.getModalEvent(state_id, this.agentType)
  );
  }

}
  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();

    this.store.dispatch([
      new InteractionActions.SetInteractionType("ticket"),
      new InteractionActions.SetIsActive(false),
      new InteractionActions.CreatedAutoTicket(false),
      new InteractionActions.ClearInitializedTicket(),
      new InteractionTicketActions.Clear({ keepAssignedHexId: !this.fromSearchPage }),
      new InteractionActions.InteractionMenuDestroyed()
    ]);
  }
}
