import { createSelector, Selector } from "@ngxs/store";
import { RAINONE_4G_UNLIMITED, RAINONE_4G_UOP, STAND_ALONE_4G, TECH_TYPES } from "src/app/constants";
import { PaymentType, RainProduct, } from "src/app/core/store/interfaces/rain-product.interface";
import { ProductsState, ProductsStateModel } from "src/app/core/store/state/product-state/products.state";
import { CustomerService } from "src/app/shared/interfaces/smartsub-data.interface";
import { TechType } from "src/app/shared/services/google-maps/assets/techtype.type";
import { SimState } from "../state/sim.state";
import { ProductCatalogState } from "src/app/core/store/state/product-state/product-catalog.state";
import { CatalogAddon, CatalogProduct, CatalogSimProduct } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { WalletTopupType } from "../interfaces/wallet.interfaces";
import { ONE_MEGABYTE_IN_BYTES } from "src/app/constants";
import { MigrationOption } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { ExtendedServiceSelectors } from "src/app/customer-info-summary-page/store/selectors/extended-service.selector";
import { ServiceFamily } from "src/app/customer-info-summary-page/store/interfaces/service-family.interface";
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";
import { MigrationServiceOption } from "src/app/sales/sales-portal/components/level-up/store/types/migrate-level.interfaces";
import { SelectedServiceSelectors } from "src/app/customer-info-summary-page/store/selectors/selected-service.selectors";
import { MobileProductVasIdState } from "../state/mobile-product-vas-id.state";
import { RAIN_WIFI_SPEED_LEVELS } from "src/app/sales/sales-portal/components/speed-up/assets/config";


const LEVEL_MAP = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
];


const MAX_PRODUCT_LEVEL = 5;

export type CombinedProduct = RainProduct | CatalogSimProduct;


export class SimProductSelector {

    @Selector([
        SimProductSelector.getSelectedCombinedProduct,
        SelectedServiceSelectors.getSelectedService,
    ])
    static getCurrentProductTechType(
        currentProduct: CombinedProduct,
        service: CustomerService,
    ): TechType | null {
        const { category } = currentProduct ?? {};
        if (TECH_TYPES.includes(category as TechType)) {
            return <TechType>category;
        }

        const { current_speed } = service ?? {};
        if (!current_speed) {
            return null;
        }

        if (current_speed?.includes("4G")) {
            return "4G";
        }
        return current_speed?.includes("5G") ? "5G" : null;
    }

    static isTechType(selectedTech: TechType) {
        return createSelector(
            [SimProductSelector.getCurrentProductTechType],
            (techType: TechType) => selectedTech === techType,
        );
    }

    static getProductById(id: string) {
        return createSelector(
            [
                ExtendedServiceSelectors.getServiceById(id),
                ProductsState
            ],
            (service: CustomerService, productState: ProductsStateModel) =>
                ProductsState.getProduct(service?.product_id)(productState),
        );
    }

    static getCombinedProductById(id: string) {
        return createSelector(
            [
                ExtendedServiceSelectors.getServiceById(id),
                SimProductSelector.getCombinedProducts,
            ],
            (service: CustomerService, products: { id: string }[]) =>
                products?.find((product) => product?.id === service?.product_id,),
        );
    }

    static getPaymentTypeByServiceId(id: string) {
        return createSelector(
            [SimProductSelector.getCombinedProductById(id)],
            (product: { paymentType: PaymentType }) => product?.paymentType ?? "unknown",
        );
    }

    @Selector([
        SelectedServiceSelectors.getSelectedService,
        ProductCatalogState.getProductsByType("sim"),
    ])
    static getSelectedRainOneSubAmounts(
        customerService: CustomerService,
        products: CatalogSimProduct[],
    ) {
        const { product_id } = customerService ?? {};

        const product = ProductCatalogState.getProductByIdAndType(product_id, "sim",)(products);
        const baseSub = product?.config?.base_subscription;
        const mapped: { [key in WalletTopupType]: number } = {
            data: 0,
            sms: 0,
            voice: 0,
        };
        mapped.data = (baseSub?.DATA ?? 0) / ONE_MEGABYTE_IN_BYTES;
        mapped.sms = baseSub?.SMS ?? 0;
        mapped.voice = (baseSub?.VOICE ?? 0) / 60;
        return mapped;
    }

    /**
     *    Will include catalog product
     */
    @Selector([
        ProductCatalogState.getProductsByType("sim"),
        SelectedServiceSelectors.getSelectedService,
    ])
    static getSelectedCatalogProduct(
        simProducts: CatalogSimProduct[],
        service: CustomerService,
    ) {
        const { product_id } = service ?? {};
        if (!product_id) {
            return null;
        }

        return ProductCatalogState.getProductByIdAndType(product_id, "sim",)(simProducts);
    }

    /**
     * Will include old and new products
     */
    @Selector([
        ProductsState.getState,
        SimProductSelector.getSelectedCatalogProduct,
        SelectedServiceSelectors.getSelectedService,
    ])
    static getSelectedCombinedProduct(
        productState: ProductsStateModel,
        simProduct: CatalogSimProduct,
        service: CustomerService,
    ): CombinedProduct {
        if (simProduct) {
            return simProduct;
        }

        const { product_id } = service ?? {};
        if (!product_id) {
            return null;
        }

        return ProductsState.getProduct(product_id)(productState);
    }

    @Selector([SimProductSelector.getSelectedCombinedProduct])
    static getSelectedSimTech(currentProduct: CombinedProduct) {
        return <TechType>currentProduct?.category;
    }

    @Selector([SimProductSelector.getSelectedCombinedProduct])
    static isRainone4GUnlimited(currentProduct: CombinedProduct) {
        return currentProduct?.name?.includes(RAINONE_4G_UNLIMITED)
    }

    @Selector([SimProductSelector.getSelectedCombinedProduct])
    static isRainone4GUOP(currentProduct: CombinedProduct) {
        return currentProduct?.name?.includes(RAINONE_4G_UOP)
    }

    @Selector([SimProductSelector.getSelectedCombinedProduct])
    static is4GStandalone(currentProduct: CombinedProduct) {
      return currentProduct?.name?.includes(STAND_ALONE_4G)
    }

    @Selector([SimProductSelector.getSelectedCombinedProduct])
    static isRain101(currentProduct: CombinedProduct) {
        return currentProduct?.name?.includes('101') // does not show for work 101s
    }

    @Selector([SimProductSelector.getSelectedCombinedProduct])
    static isRain1015GWork(currentProduct: CombinedProduct) {
        const { name, category} = currentProduct ?? {}
        return name?.includes('work') && category === '5G'
    }

    static isSelectedSimThisTech(techType: TechType) {
        return createSelector(
            [SimProductSelector.getSelectedSimTech],
            (tech: TechType) => techType === tech,
        );
    }

    @Selector([SimProductSelector.getSelectedCatalogProduct])
    static getMigrationOptions(product: CatalogSimProduct) {
        return product?.config?.migration ?? [];
    }

    static getFullMigrationOptionsByMaxLevel(maxLevel = MAX_PRODUCT_LEVEL) {

        return createSelector(
            [
                SimProductSelector.getMigrationOptions,
                ProductCatalogState.getProductsByTypeMappedById("sim")
            ],
            (migrationOptions: MigrationOption[], mappedSimProducts: Dictionary<CatalogSimProduct>): CatalogSimProduct[] => {
                return migrationOptions
                    .map(option => mappedSimProducts?.[option?.id])
                    .filter(product => SFValidators.isDefined(product) && product?.config?.level <= maxLevel);
            }
        )
    }

    @Selector([SimProductSelector.getMigrationOptions])
    static hasMigrationOptions(options: MigrationOption[]) {
        return options?.length > 0;
    }

    @Selector([SimProductSelector.getSelectedCatalogProduct])
    static getMultiplierAmounts(product: CatalogSimProduct) {
        return product?.config?.topup_multiplier;
    }

    @Selector([
        ProductCatalogState.getProductsByType("sim"),
        ProductsState.getProductsByType("product"),
    ])
    static getCombinedProducts(
        catalogProducts: CatalogSimProduct[],
        normalProducts: RainProduct[],
    ) {
        const mappedCatalogProducts = catalogProducts?.map((p) => {
            return {
                paymentType: p?.config?.paymentType,
                ...p,
            };
        });
        return [...mappedCatalogProducts ?? [], ...normalProducts ?? []];
    }

    static getCurrentMigrationOptionsForLevel(level: number) {
        return createSelector(
            [
                SelectedServiceSelectors.getSelectedServiceFamily,
                ProductCatalogState.getProductsByTypeMappedById("sim"),
                ProductCatalogState.getProductsMappedById,
                SimProductSelector.is4GStandalone
            ],
            (serviceFamily: ServiceFamily, simProductDict: Dictionary<CatalogSimProduct>, catalogDict: Dictionary<CatalogProduct>, is4GStandalone: boolean): MigrationServiceOption[] => {

                const { parentService, children } = serviceFamily ?? {};

                return [...children, parentService]
                    .map((service) => {

                        const { id, product_id, service_reference } = service ?? {};
                        const migrationOptions = simProductDict?.[product_id]?.config?.migration;

                        const migrationOption = migrationOptions?.find(option => {

                          if(is4GStandalone){
                            return catalogDict?.[option?.id]?.config?.['level'] === level
                          }
                          return simProductDict?.[option?.id]?.config?.level === level
                        }
                        );

                        if (!migrationOption?.id) {
                            return null;
                        }

                        return {
                            migrationOption,
                            serviceId: id,
                            msisdn: service_reference
                        };
                    })
                    .filter(migrationOption => migrationOption);
            },
        );
    }

    @Selector([
      SimProductSelector.getSelectedCombinedProduct,
      MobileProductVasIdState.getMobileLevel
    ])
    static getCurrentProductLevel(
      selectedProduct: CatalogSimProduct,
      mobileLevel: number
    ): number {
      return selectedProduct?.config?.level ?? mobileLevel;
    }

    @Selector([
      SimProductSelector.getSelectedCombinedProduct,
      SimProductSelector.is4GStandalone,
      MobileProductVasIdState.getMobileLevel
    ])
    static getCurrentProductDisplayLevel(
      selectedProduct: CatalogSimProduct,
      is4GStandalone: boolean,
      mobileLevel: number
    ): string {
        const level = is4GStandalone ? mobileLevel : selectedProduct?.config?.level;
        return SFValidators.isNotDefined(level) ? "unknown" : LEVEL_MAP[level];
    }

    @Selector([
        ExtendedServiceSelectors.getServices,
        SimState.getSelectedID,
        SimProductSelector.getCombinedProducts
    ])
    static getPaymentType(services: CustomerService[], id: string, combinedProducts: CombinedProduct[]) {

        const service = services?.find(service => service?.id === id)
        const product = combinedProducts?.find(product => product?.id === service?.product_id)

        if (SFValidators.hasProperty(<RainProduct>product, "paymentType")) {
            return (<RainProduct>product)?.paymentType;
        }

        return (<CatalogSimProduct>product)?.config?.paymentType ?? "unknown";

    }

    /**
    * checks for rainone product by using product ID from order lines
    */
    static isRainOneProduct(orderLineProductId: string) {
        return createSelector([ProductCatalogState.getProductsByType("sim")],
            (products?: CatalogSimProduct[]) => {
                const product = products.find(product => product?.id === orderLineProductId);
                if (!product?.config || product.config?.legacy) {
                    return false;
                }
                return true;
            }
        )
    }

    @Selector([
        ProductCatalogState.getProductsByTypeMappedById("sim"),
        SimProductSelector.getSelectedCatalogProduct,
        SimProductSelector.is4GStandalone,
        ProductCatalogState.getMobileProductLevels
    ])
    static getLevelupOptions(
        simProductDict: Dictionary<CatalogSimProduct>,
        currentProduct: CatalogSimProduct,
        is4GStandalone: boolean,
        mobileProductLevels
    ) {

        const { migration } = currentProduct?.config ?? {};

        if(is4GStandalone){
          return mobileProductLevels
        }

        return (migration ?? []).map(option => simProductDict?.[option.id]);

    }

    static getLevelUpDisplayOptions({
        includeCurrentLevel = true,
        minLevel = 0,
        maxLevel = 5
    }: { includeCurrentLevel?: boolean, minLevel?: number, maxLevel?: number } = {}) {

        return createSelector(
            [
                SimProductSelector.getLevelupOptions,
                SimProductSelector.getSelectedCatalogProduct
            ],
            (options: CatalogSimProduct[], currentProduct: CatalogSimProduct) => {

                const allOptions = includeCurrentLevel ? [...options ?? [], currentProduct] : options ?? [];

                const getLevel = (p: CatalogSimProduct) => p?.config?.level;

                return allOptions
                    .filter(option => {
                        const level = getLevel(option);
                        return level >= minLevel && level <= maxLevel;
                    })
                    .sort((optionA, optionB) => getLevel(optionA) - getLevel(optionB))


            }
        )

    }

    @Selector([
      SimProductSelector.getSelectedCombinedProduct
    ])
    static getCurrentProductAddons(
      selectedProduct: CatalogSimProduct
    ): { id: string }[] {
      return selectedProduct?.config?.add_ons;
    }

    @Selector([
      SimProductSelector.getCurrentProductAddons,
      ProductCatalogState.getProductsByType('addon'),
      SelectedServiceSelectors.getSelectedService
    ])
    static getCurrentProductAddonsDisplay(
      selectedProductAddons: { id: string, allowed?: string[] }[],
      addons: CatalogAddon[],
      selectedService: CustomerService
    ){

        const selectedProductHasAllowedAddons = selectedProductAddons?.some(selectedAddon => selectedAddon?.allowed)
        const selectedAddonIds = selectedProductAddons?.map(addon => addon?.id);

        if(selectedProductHasAllowedAddons){
          const { id: subscriptionId } = selectedService?.subscriptions?.find(subscription => selectedAddonIds?.includes(subscription?.id)) ?? {}

          const { allowed } = selectedProductAddons?.find( addon => addon?.id === subscriptionId) ?? {}
          const allowedAddons = addons?.filter(addon => allowed?.includes(addon?.id))
          const allowedAddonsIds = allowedAddons?.map(addon => addon?.id)

          return RAIN_WIFI_SPEED_LEVELS.filter(level => allowedAddonsIds?.includes(level?.id))

        }

        const matchedAddons = addons?.filter(addon => selectedAddonIds?.includes(addon?.id));
        const matchedAddonsIds = matchedAddons?.map(addon => addon?.id )

        return RAIN_WIFI_SPEED_LEVELS.filter(level => matchedAddonsIds?.includes(level?.id))

    }

}
