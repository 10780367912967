import { NormalSiteFeatureCollection } from "src/app/shared/services/google-maps/assets/map-site.interfaces";


export const getFilteredFeatures = (features: NormalSiteFeatureCollection): NormalSiteFeatureCollection => {

  if (!features?.length) {
    return []
  }

  return features.filter(feature => {

    const { coordinates } = feature?.geometry ?? {}

    return coordinates?.every(coordinate => typeof coordinate === 'number')

  })

}