import { Ticks } from "src/app/shared/components/chat-elements/chat-bubble-template/elements/chat-ticks/ticks.type";
import { WHATSAPP_INVOCATION_SOURCE } from "../constants/whatsapp.constants";
import {
    CurrentWhatsappMessageTypes,
    FormattedWhatsappMessage,
    WhatsappDeliveryStatus,
    WhatsappMessage,
} from "../types/whatsapp-message.interface";
import {
    WhatsappIdentifier,
    WhatsappSendMessageRequiredPayload,
} from "../types/whatsapp-send-message-payload.interface";

type GetDisplayDetailsArgs = Pick<
    WhatsappMessage["message"],
    | "recipientName"
    | "recipient"
    | "senderName"
    | "senderId"
    | "invocationSource"
> & { inbound: boolean };

const TICK_MAP: { [key in WhatsappDeliveryStatus]: Ticks } = {
    SENT: "one",
    DELIVERED: "two",
    READ: "two-blue",
    FAILED: "failed",
    DELETED: "deleted",
};

export const convertToFormattedWhatsappMessage = (
    msg: WhatsappMessage<CurrentWhatsappMessageTypes>,
): FormattedWhatsappMessage => {
    const {
        id,
        message,
        inbound,
        deliveryStatus,
        messageType,
        invocationSource,
        recipient,
        recipientName,
        messagePreview,
        insertedAt,
    } = msg ?? {};
    const {
        whatsappInboundMessage,
        whatsappOutboundMessage,
        timestamp,
        presignedUrl,
        mimetype,
        bucketName,
        keyName,
    } = message ?? {};

    const content = inbound ? whatsappInboundMessage : whatsappOutboundMessage;

    const mediaMessage = {
        presignedUrl,
        mimetype,
        bucketName,
        keyName,
    };

    return {
        id,
        type: messageType,
        ...getDisplayDetails({
            inbound,
            invocationSource,
            recipient,
            recipientName,
            senderId: id,
            senderName: "",
        }),
        content: {
            type: messageType,
            textMessage: { body: messagePreview },
            imageMessage: { ...mediaMessage },
            audioMessage: { ...mediaMessage },
            videoMessage: { ...mediaMessage },
        },
        timestamp,
        insertedAt,
        direction: inbound ? "left" : "right",
        ticks: deliveryStatus ? TICK_MAP[deliveryStatus] : "none",
    };
};

const getDisplayDetails = ({
    recipientName,
    recipient,
    senderName,
    senderId,
    inbound,
    invocationSource,
}: GetDisplayDetailsArgs): { name: string; chatLetter: string } => {
    //inbound is from customer
    if (inbound) {
        const useName = recipientName ? true : false;
        const name = useName ? recipientName : recipient;

        return {
            name,
            chatLetter: useName ? firstCharUp(name) : "C",
        };
    }

    const fallbackName = `rain - ${senderName ? senderName : senderId}`;
    const name = getAgentEmail(invocationSource) ?? fallbackName;
    return {
        name,
        chatLetter: firstCharUp(name),
    };
};

const firstCharUp = (word: string) => word?.charAt(0)?.toUpperCase();

const getAgentEmail = (invocationSource: string) =>
    invocationSource?.split("-")?.[1];

export const createWhatsappMsgPayload = (
    message: string,
    agentEmail: string,
    identifier: WhatsappIdentifier,
): WhatsappSendMessageRequiredPayload => {
    const defaultOptions = {
        invocationSource: `${WHATSAPP_INVOCATION_SOURCE}-${agentEmail}`,
        text: message,
        recipientMsisdn: identifier?.["recipient"],
        whatsappOutboundMessage: {
            type: "TEXT" as const,
            textMessage: {
                body: message,
            },
        },
    };

    if ("recipient" in identifier) {
        return {
            ...defaultOptions,
            recipient: identifier.recipient,
        } as const;
    }

    if ("recipientCustomerId" in identifier) {
        return {
            ...defaultOptions,
            recipient: identifier.recipientCustomerId,
        } as const;
    }

    return null;
};
