import { SpeedupPolicyOption } from "src/app/customer-info-summary-page/store/interfaces/basic-customer-service.interface";
import { HomeOrWork } from "src/app/customer-info-summary-page/store/state/home-or-work-services.state";



//TODO: cleanup config
export const RAIN_WIFI_SPEED_LEVELS: WifiSpeedupConfig[] = [
  {
    id: '6e1c27e8-1a8a-4940-bab4-3998a5c5867c',
    level: 30,
    name: '30 Mbps',
    speed: '30',
    price: 0,
    cost: 'FREE',
    levelCost: 'included in rainone',
    policy: 'High_Speed_30',
    type: 'home'
  },
  {
    id: '5859e1ac-9ebf-4f47-9068-eee5cc8b0361',
    level: 60,
    name: 'Level up 60 Mbps',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'home'
  },
  {
    id: 'e74c503d-34f5-4c64-9cea-1f9b168f8922',
    level: 100,
    name: 'Level up 100+ Mbps',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'home'
  },
  {
    id: '627e0a1a-a42d-4ea4-a49d-916e714aae34',
    level: 30,
    name: 'Level 30 Mbps AU',
    speed: '30',
    price: 0,
    cost: 'FREE',
    levelCost: 'included in rainone',
    policy: 'High_Speed_30',
    type: 'work'
  },
  {
    id: 'd04ae4f2-cec3-438d-96cb-8d99d8d76568',
    level: 60,
    name: 'Level up SME 60 Mbps AU',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'work'
  },
  {
    id: '95e89ca9-5f42-4232-ad6c-93844e1d7e91',
    level: 100,
    name: 'Level up SME 100plus Mbps AU',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'work'
  },

  // new products speed config

  // HOME PRO

  // home 60 speed ups
  {
    id: 'dfd4b9c4-53ce-4a85-9ad1-f97ffed58aa3',
    level: 60,
    name: 'Level up 60 Mbps 101 pro',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'home'
  },

  {
    id: 'f6287c24-a7a5-4e23-b295-df914d634dd9',
    level: 60,
    name: 'up to 60 Mbps - 1 xtender',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'home'
  },

  {
    id: '14226b51-a504-4ae7-8e3d-695123c109f2',
    level: 60,
    name: 'up to 60 Mbps - 2 xtenders',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'home'
  },

  // home 100 speed ups

  {
    id: 'b1b4d3e0-0533-4500-8a3f-fb342e13a61e',
    level: 100,
    name: 'Level up 100+ Mbps 101 pro',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'home'
  },

  {
    id: 'eeb024a0-e738-41fb-b54c-52683a797aec',
    level: 100,
    name: 'unlimited 5G speeds - 1 xtender',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'home'
  },

  {
    id: '7dd4e239-332c-4845-9934-e115ba26c0f6',
    level: 100,
    name: 'unlimited 5G speeds - 2 xtenders',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'home'
  },

  // WORK PRO

  // work 60 speed ups
  {
    id: '1c4a0b61-e4e4-4a04-84f2-16e5b232d82b',
    level: 60,
    name: 'Level up 60 Mbps 101.pro AU',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'work'
  },

  {
    id: '36ae8e42-ed67-45af-8075-1be69b23f519',
    level: 60,
    name: 'up to 60 Mbps - 1 xtender - work',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'work'
  },

  {
    id: '323ea32c-31b3-4573-a3ae-40d1a3764c1c',
    level: 60,
    name: 'up to 60 Mbps - 2 xtenders - work',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'work'
  },

  // work 100 speed ups

  {
    id: 'f0f98ce1-2920-4c62-8455-2a20da86740d',
    level: 100,
    name: 'Level up 100+ Mbps 101.pro AU',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'work'
  },
  {
    id: 'e7bf0fef-bb3c-45e5-84ad-7688e0527463',
    level: 100,
    name: 'unlimited 5G speeds - 1 xtender - work',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'work'
  },
  {
    id: '6852084d-dbf5-4efa-9543-5e883544ab2d',
    level: 100,
    name: 'unlimited 5G speeds - 2 xtenders - work',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'work'
  },

];


export const RAIN_WIFI_BASE_LEVEL = RAIN_WIFI_SPEED_LEVELS[0];
export const RAIN_WORK_WIFI_BASE_LEVEL = RAIN_WIFI_SPEED_LEVELS[3];

export type WifiSpeedupConfig = {
  id: string;
  level: number;
  name: string;
  speed: "30" | "60" | "100+";
  price: number;
  cost: string;
  levelCost: string;
  policy: SpeedupPolicyOption;
  type: HomeOrWork
}
