import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { InteractionActions } from 'src/app/interactions/store/actions/interaction-actions';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { WhatsappStompSocketService } from '../../services/whatsapp-stompsocket.service';
import { WhatsappService } from '../../services/whatsapp.service';
import { WhatsappMessageActions } from '../actions/whatsapp-message-actions';
import { WhatsappNotificationActions } from '../actions/whatsapp-notification-actions';
import { patchWhatsappMessages } from '../functions/add-in-whatsapp-msgs';
import { validateWhatsappIdentifier } from '../functions/validate-whatsapp-identifier';
import { WhatsappMessage } from '../types/whatsapp-message.interface';
import { WhatsappIdentifier } from '../types/whatsapp-send-message-payload.interface';

const NO_MORE_MESSAGES_ERROR = "No more messages.";

const getDefaults = (): WhatsappMessagesStateModel => ({
    ...getDataLoadingDefaultValues([]),
    silentlyLoading: false,
    page: 0,
    pageSize: 50,
    paginating: false,
    currentIdentifier: null
});

interface WhatsappMessagesStateModel extends DataLoading<WhatsappMessage[]> {
    silentlyLoading: boolean;
    page: number;
    pageSize: number;
    paginating: boolean;
    currentIdentifier: WhatsappIdentifier;
}
@State<WhatsappMessagesStateModel>({
    name: 'sf_whatsapp_messages_state',
    defaults: getDefaults()
})
@Injectable()
export class WhatsappMessagesState {

    @Selector()
    static isLoading(state: WhatsappMessagesStateModel) { return state.loading }

    @Selector()
    static isPaginating(state: WhatsappMessagesStateModel) { return state.paginating }

    @Selector()
    static isPaginatingOrLoading(state: WhatsappMessagesStateModel) {
        const { paginating, loading } = state;
        return paginating || loading;
    }

    @Selector()
    static isPaginatingOrSilentlyLoading(state: WhatsappMessagesStateModel) {
        const { paginating, silentlyLoading } = state;
        return paginating || silentlyLoading;
    }


    @Selector()
    static isSilentlyReloading(state: WhatsappMessagesStateModel) { return state.silentlyLoading }

    @Selector()
    static isLoaded(state: WhatsappMessagesStateModel) { return state.loaded }

    @Selector()
    static getData(state: WhatsappMessagesStateModel) { return state.data ?? [] }

    @Selector([
        WhatsappMessagesState.getData
    ])
    static getSortedData(data: WhatsappMessage<"TEXT">[]) {
        return Utils.Helpers.SortBy([...data], "insertedAt", "asc");
    }

    @Selector()
    static getError(state: WhatsappMessagesStateModel) { return state.error }

    static getMessageByID(id: string) {
        return createSelector(
            [WhatsappMessagesState],
            (state: WhatsappMessagesStateModel) => state.data?.find(msg => msg?.id === id)
        );
    }

    private readonly _cancelRequest$ = new Subject<null>();

    constructor(private store: Store,
        private whatsappService: WhatsappService,
        private whatsappStompservice: WhatsappStompSocketService) {
    }

    private fetchResponseActionHandlers<S, F>(
        ctx: StateContext<WhatsappMessagesStateModel>,
        successAction: new (messages: WhatsappMessage[]) => S,
        failAction: new (arg: unknown) => F,
        defaultError?: string
    ) {

        return (source$: Observable<{ data: WhatsappMessage[] }>) =>
            source$.pipe(

                tap({
                    next: res => {
                        if (!res?.data?.length) {
                            return ctx.dispatch(new failAction(defaultError));
                        }
                        return ctx.dispatch(new successAction(res.data));
                    },
                    error: (e: unknown) => ctx.dispatch(new failAction(e))
                }),
                takeUntil(this._cancelRequest$.pipe(take(1)))
            );

    }

    private getCurrentIdentifier(ctx: StateContext<WhatsappMessagesStateModel>, displayError = false) {
        const { currentIdentifier } = ctx.getState();
        validateWhatsappIdentifier(currentIdentifier, displayError);
        return currentIdentifier;
    }


    private getInitialIdentifier(): WhatsappIdentifier {
        //TODO: also check route and web socket connection
        return this.store.selectSnapshot(CustomerInfoState.getWhatsappIdentifier);
    }

    @Action(WhatsappMessageActions.Init)
    Init(ctx: StateContext<WhatsappMessagesStateModel>) {
        const { data } = ctx.getState();
        const identifier = this.getInitialIdentifier();

        //Will throw error if not defined
        validateWhatsappIdentifier(identifier);

        ctx.patchState({
            currentIdentifier: identifier
        });

        const action = data?.length ? new WhatsappMessageActions.PatchData() : new WhatsappMessageActions.Fetch();

        return ctx.dispatch(action)
            .pipe(
                tap({
                    next: () => this.whatsappStompservice.createWatchers(identifier)
                })
            )
    }


    @Action(WhatsappMessageActions.Fetch)
    Fetch(ctx: StateContext<WhatsappMessagesStateModel>) {
        const { pageSize } = ctx.getState();
        const identifier = this.getCurrentIdentifier(ctx, true);

        ctx.patchState({ loading: true });

        return this.whatsappService.fetchMessages({ ...identifier, page: 0, pageSize })
            .pipe(this.fetchResponseActionHandlers(
                ctx,
                WhatsappMessageActions.FetchSuccess,
                WhatsappMessageActions.FetchFail,
                "No messages in the payload."
            ));
    }


    @Action(WhatsappMessageActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<WhatsappMessagesStateModel>, action: WhatsappMessageActions.FetchSuccess) {
        const { payload } = action;
        DataLoadingHelper.setData(ctx, Utils.Helpers.nestedSortBy(payload, "message.timestamp"));
    }

    @Action(WhatsappMessageActions.FetchFail)
    FetchFail(ctx: StateContext<WhatsappMessagesStateModel>, action: WhatsappMessageActions.FetchFail) {
        const error = Utils.Helpers.findError(action.error, '');
        const errorMessage = `Failed to load messages. ${error}`;

        ctx.patchState({
            ...DataLoadingHelper.errorLoaded(errorMessage),
            silentlyLoading: false
        });
    }

    @Action(WhatsappMessageActions.FetchNextPage)
    FetchNextPage(ctx: StateContext<WhatsappMessagesStateModel>) {
        const { page, pageSize } = ctx.getState();
        const identifier = this.getCurrentIdentifier(ctx, true);

        ctx.patchState({
            paginating: true
        });

        return this.whatsappService.fetchMessages({ ...identifier, page: page + 1, pageSize })
            .pipe(this.fetchResponseActionHandlers(
                ctx,
                WhatsappMessageActions.FetchNextPageSuccess,
                WhatsappMessageActions.FetchNextPageFail,
                NO_MORE_MESSAGES_ERROR
            ));
    }

    @Action(WhatsappMessageActions.FetchNextPageSuccess)
    FetchNextPageSuccess(ctx: StateContext<WhatsappMessagesStateModel>, action: WhatsappMessageActions.FetchNextPageSuccess) {
        const { data, page } = ctx.getState();
        const { payload } = action;

        ctx.patchState({
            data: patchWhatsappMessages(data, payload),
            paginating: false,
            page: page + 1 //Increment the current page if data loaded successfully
        });
    }

    @Action(WhatsappMessageActions.FetchNextPageFail)
    FetchNextPageFail(ctx: StateContext<WhatsappMessagesStateModel>, action: WhatsappMessageActions.FetchNextPageFail) {
        const error = Utils.Helpers.findError(action.error, '');
        const isWarning = error === NO_MORE_MESSAGES_ERROR;
        const message = isWarning ? error : `Failed to load more messages. ${error}`;

        ctx.patchState({
            paginating: false
        });

        return ctx.dispatch(new AddSuccessResponseNotification(
            {
                success: false,
                message,
            },
            isWarning
        ));
    }

    @Action(WhatsappMessageActions.ReceivedNewMessage)
    ReceivedNewMessage(ctx: StateContext<WhatsappMessagesStateModel>, action: WhatsappMessageActions.ReceivedNewMessage) {
        const { data } = ctx.getState();
        const { message } = action;

        ctx.patchState({
            data: patchWhatsappMessages(data, [message]),
        });

        return ctx.dispatch([
            new WhatsappNotificationActions.Add(message?.id),
            //Also reload the data to ensure no data was missed
            new WhatsappMessageActions.PatchData()
        ]);
    }


    @Action(WhatsappMessageActions.ReceivedUpdatedMessage)
    ReceivedUpdatedMessage(ctx: StateContext<WhatsappMessagesStateModel>, action: WhatsappMessageActions.ReceivedUpdatedMessage) {
        const { data } = ctx.getState();
        const { message } = action;

        ctx.patchState({
            data: patchWhatsappMessages(data, [message]),
        });
    }

    @Action(WhatsappMessageActions.PatchData)
    PatchData(ctx: StateContext<WhatsappMessagesStateModel>, action: WhatsappMessageActions.PatchData) {
        const { page, pageSize } = action.options;

        const identifier = this.getCurrentIdentifier(ctx);

        ctx.patchState({
            silentlyLoading: true
        });

        return this.whatsappService.fetchMessages({ ...identifier, page, pageSize })
            .pipe(this.fetchResponseActionHandlers(
                ctx,
                WhatsappMessageActions.PatchDataSuccess,
                WhatsappMessageActions.PatchDataFail,
                "Failed to update messages."
            ));
    }

    @Action(WhatsappMessageActions.PatchDataSuccess)
    PatchDataSuccess(ctx: StateContext<WhatsappMessagesStateModel>, action: WhatsappMessageActions.PatchDataSuccess) {
        const { data } = ctx.getState();
        const { payload } = action;

        ctx.patchState({
            silentlyLoading: false,
            data: patchWhatsappMessages(data, payload)
        });
    }

    @Action(WhatsappMessageActions.PatchDataFail)
    PatchDataFail(ctx: StateContext<WhatsappMessagesStateModel>) {
        //TODO: decide what to do with error

        ctx.patchState({
            silentlyLoading: false
        });
    }

    @Action([WhatsappMessageActions.Clear, InteractionActions.InteractionMenuDestroyed])
    Clear(ctx: StateContext<WhatsappMessagesStateModel>) {
        this._cancelRequest$.next(null);
        this.whatsappStompservice.deactivate();
        ctx.setState(getDefaults());
    }

}


