import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { Invitation, SessionState } from 'sip.js';
import { isRainSupportNumber } from 'src/app/shared/functions/is-rain-support-number.function';
import { Utils } from 'src/app/Utils';
import { ICallQueues } from '../assets/call-queue.interface';
import { CallStateMessage } from '../assets/call-state-message.interface';
import { SipCallType } from '../assets/sip-call.type';
import { SipFunctions } from '../assets/sip-functions';
import { SipMessageSubject } from '../assets/sip-message-subject.type';
import { SipPhoneService } from '../services/sip-phone.service';
import { SipPhoneActions } from '../store/actions/sip-phone-actions';
import { VoiceCampaignActions } from '../store/actions/voice-campaign-actions';
import { VoiceCampaignState } from '../store/state/voice-campaign.state';
import { IncomingCallService } from './incoming-call.service';
import { SetBusy, SetConnectingCall } from 'src/app/shared/components/agent/store/actions/agent-status-actions/agent-status.actions';
import { AgentActions } from 'src/app/shared/components/agent/store/actions/agent-action-actions';


@Component({
  selector: 'app-incoming-call-modal',
  templateUrl: './incoming-call-modal.component.html',
  styleUrls: ['./incoming-call-modal.component.scss', '../assets/sip-phone-styles.scss']
})
export class IncomingCallModalComponent implements OnInit, OnDestroy {

  private _canMinimize = true;
  @Input() set canMinimize(value: boolean) {
    this._canMinimize = value;
    if (!this._canMinimize) this.minimized = false;
  }

  get canMinimize() {
    return this._canMinimize;
  }

  @Input() callQueues: ICallQueues[];

  incomingModalOpen = false;
  callerNumber = "";
  customerId = "";
  displayName = "";
  session: Invitation;
  callStateMessage: CallStateMessage = null;
  showCallTimer = false;
  minimized = false;
  callType: SipCallType;
  canEndCallMessages: CallStateMessage[] = ['connecting call..', 'connected', 'on hold'];

  private callSub: Subscription;

  constructor(
    private store: Store,
    private sipPhoneService: SipPhoneService,
    private incomingCallService: IncomingCallService
  ) {
  }


  ngOnInit(): void {
    this.callSub = this.sipPhoneService.callSubject.subscribe(
      (response: Map<SipMessageSubject, any>) => {
        if (response.has("invitation")) this.onInvite(response.get("invitation"));
        else if (response.has("state_changed")) {
          if (this.callType === "outgoing") return;
          this.handleStateChange(response.get("state_changed"));
        }
        else if (response.has("call_type_changed")) this.callType = response.get("call_type_changed");
        else if (response.has("error")) this.onTerminate();
      }
    );
  }

  toggleMinimized() {
    this.minimized = !this.minimized;
  }

  onInvite(invitation: Invitation) {
    this.minimized = false;
    this.callStateMessage = "incoming call";
    this.initCallFlow(invitation);

  }

  private initCallFlow(invitation: Invitation) {
    const { headers, from } = invitation?.request ?? {};
    const { displayName, uri } = from ?? {};
    const hasOnlyNumbers = Utils.Regex.hasOnlyNumbers(displayName);

    this.callerNumber = hasOnlyNumbers ? displayName : uri?.user?.replace("+", "");
    this.displayName = SipFunctions.getCustomerFromHeader(headers) ?? displayName;
    this.session = invitation;
    this.sipPhoneService.handleSession(this.session);

    if (SipFunctions.isCampaign(headers)) {
      this.autoAcceptCall(invitation);
    }
    this.incomingModalOpen = true;
  }

  onRejectCall() {
    this.sipPhoneService.endCall();
    this.store.dispatch([
      new AgentActions.CallRejected(),
      new SetBusy()
    ]);
  }

  //When the button is pressed
  onAcceptCall() {
    if (this.session instanceof Invitation) {
      this.acceptInvitation(this.session);
      this.normalCallFlow();
    }
    this.sipPhoneService.toggleSounds(false);
  }


  private autoAcceptCall(invitation: Invitation) {
    this.acceptInvitation(invitation);

    this.store.dispatch(new VoiceCampaignActions.SetupTicketFlow({
      customerNumber: this.callerNumber,
      ...SipFunctions.getCustomHeaderInfo(invitation?.request?.headers)
    }));
  }

  private acceptInvitation(invitation: Invitation) {
    invitation.accept();

    this.store.dispatch([
      new AgentActions.CallAccepted(),
      new SetConnectingCall("incoming")
    ]);
  }

  private normalCallFlow() {
    if (isRainSupportNumber(this.callerNumber)) {
      this.incomingCallService.navigateBasedOnIdentification(false);
      return;
    }
    const formattedNumber = Utils.Formatters.formatPhoneNumber(this.callerNumber);
    this.incomingCallService.setupTicketInfo(formattedNumber,this.session?.request?.headers?.['Q-Customerid']?.[0]?.raw);
  }

  onEndCall() {
    this.store.dispatch(new AgentActions.CallEnded());
    this.sipPhoneService.endCall();
  }

  onHold() {
    if (SessionState.Established === 'Established') {
      this.sipPhoneService.onHold()
      this.callStateMessage = "on hold";
    }
  }

  async resumeCall() {
    this.sipPhoneService.resumeCall()
    this.callStateMessage = 'connected'
  }

  transferCall(queue: ICallQueues) {
    this.sipPhoneService.transferCall(queue);
  }

  handleStateChange(state: SessionState) {
    switch (state) {
      case SessionState.Initial:
        break;
      case SessionState.Establishing:
        this.callStateMessage = "connecting call..";
        this.setIncomingCallDetails();
        break;
      case SessionState.Established:
        this.onEstablished();
        break;
      case SessionState.Terminating:
        this.callStateMessage = "ending call..";
        break;
      case SessionState.Terminated:
      default:
        this.onTerminate();
    }
  }

  onEstablished() {
    this.sipPhoneService.toggleSounds(false);
    this.callStateMessage = "connected";
    this.startTimer();
  }

  private setIncomingCallDetails() {
    const invitation = <Invitation>this.session;
    const { headers, callId } = invitation?.request ?? {};
    const headerInfo = SipFunctions.getCustomHeaderInfo(headers);
    const customerCallId = headerInfo?.['Q-Customer-Callid']
    const recordingFileName = headerInfo?.['Q-Recording-File-Name']
    const campaignType = headerInfo?.['Q-Campaign-Type']
    const callIdHeader = headerInfo?.['Call-ID']
    const isProgressive = campaignType === "progressive"
    const queue = headerInfo?.['Q-Queue']

    const callRecordingId = isProgressive ? customerCallId : callIdHeader;

    this.store.dispatch(new SipPhoneActions.SetIncomingCallDetails({
      callId,
      recordingId: callRecordingId,
      phoneNumber: this.callerNumber,
      recordingFileName,
      queue
    }));
  }

  onTerminate() {
    this.session = null;
    this.callerNumber = "";
    this.incomingModalOpen = false;
    this.showCallTimer = false;
  }

  startTimer() {
    this.showCallTimer = true;
  }

  getCallMessage(callStateMessage: CallStateMessage) {
    const isCampaign = this.store.selectSnapshot(VoiceCampaignState.inCampaignCall);
    return isCampaign ? SipFunctions.getCampaignMessage(callStateMessage) : callStateMessage;
  }

  ngOnDestroy() {
    if (this.callSub) this.callSub.unsubscribe();
  }
}
